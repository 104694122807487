<style lang="scss">
@import "~@/assets/css/var";
.my-table-one {
    &__title{
        font-weight: bold;
        margin: 20px;    
        font-size: 14px;
    }
    .ts-table {
        background: #fff;
        padding-right: 10px;
        margin-top: 0 !important;
    }
    .my-table {
        padding: 20px 10px;
    }
}
</style>

<template>
    <div class="common-table my-table-one">
        <div class="my-table-one__title">
            <div style="display: flex; align-items: center;">
                <div>{{ invRow.comName }}入药 - 药品数量: {{ drugsNumber }}个</div>
                <el-select style="width: 150px; padding: 0 10px;" v-model="comObj.value" 
                    filterable remote reserve-keyword placeholder="请输入药品名称"
                    :remote-method="remoteMethodCom" clearable :loading="comObj.loading">
                    <el-option v-for="item in comObj.options" :key="item.value"
                        :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
                <el-button style="margin-left: 10px;" type="primary" @click="onSearch">查询</el-button>
            </div>
        </div>
        <div class="ts-table">
            <ts-table class="make-table" style="flex: 3" :cols="cols" hide-border :data="list" 
                :page-param="pageParam" :loading="loading" hide-detail :height="null" :cmds="cmds"
                @handleSizeChange="handleSizeChange" @handleSort="sort" @handleCurrentChange="handleCurrentChange" >
            </ts-table>
        </div>
    </div>
</template>

<script>
import myMixin from '@/util/mixin'
// import variable from "@/util/variable";
export default {
    mixins: [myMixin],
    data() {
        return {
            loading: false,
            stop: true,
            pageParam: {
                pageNo: 1,
                pageSize: 10,
                total: 0,
            },
            defalutSize: 10,
            currentType: '',
            conditionList: [],
            list: [],
            cols: this.$help.generalCols([
                {
                    fieldName: 'comName',
                    name: '药品名称',
                    width: 100,
                    listQueryView: '是',
                    listQueryModel: 1,
                },
                {
                    fieldName: 'pack',
                    name: '计价单位',
                    width: 80,
                    listQueryView: '是',
                    listQueryModel: 1,
                },
                {
                    fieldName: 'lastPrice',
                    name: '最新价格',
                    width: 80,
                    listQueryView: '是',
                    listQueryModel: 1,
                    // listOrderView: '是',
                },
                {
                    fieldName: 'lastWinDate',
                    name: '最新价格时间',
                    width: 100,
                    listQueryView: '是',
                    listQueryModel: 1,
                    // listOrderView: '是',
                },
                {
                    fieldName: 'avgPrice',
                    name: '一年内均价',
                    width: 80,
                    listQueryView: '是',
                    listQueryModel: 1,
                    // listOrderView: '是',
                },
                {
                    fieldName: 'maxPrice',
                    name: '最高价',
                    width: 80,
                    listQueryView: '是',
                    listQueryModel: 1,
                    // listOrderView: '是',
                },
                {
                    fieldName: 'minPrice',
                    name: '最低价',
                    width: 80,
                    listQueryView: '是',
                    listQueryModel: 1,
                    // listOrderView: '是',
                },
                {
                    fieldName: 'lastYearMaxPrice',
                    name: '近一年的最高价',
                    width: 80,
                    listQueryView: '是',
                    listQueryModel: 1,
                    // listOrderView: '是',
                },
                {
                    fieldName: 'lastYearMaxPriceDate',
                    name: '最高价出现的时间',
                    width: 100,
                    listQueryView: '是',
                    listQueryModel: 1,
                    // listOrderView: '是',
                },
                {
                    fieldName: 'lastYearMaxPriceProvince',
                    name: '最高价出现的省份',
                    width: 80,
                    listQueryView: '是',
                    listQueryModel: 1,
                },
                {
                    fieldName: 'lastYearMinPrice',
                    name: '近一年的最低价',
                    width: 80,
                    listQueryView: '是',
                    listQueryModel: 1,
                    // listOrderView: '是',
                },
                {
                    fieldName: 'lastYearMinPriceDate',
                    name: '最低价出现的时间',
                    width: 100,
                    listQueryView: '是',
                    listQueryModel: 1,
                    // listOrderView: '是',
                },
                {
                    fieldName: 'lastYearMinPriceProvince',
                    name: '最低价出现的省份',
                    width: 80,
                    listQueryView: '是',
                    listQueryModel: 1,
                },
            ]),
            cmds: [],

            rank1: [],
            rank2: [],

            invRow: {},
            drugsNumber: 0,

            comObj: {
                value: '',
                loading: false,
                options: []
            }
        }
    },
    created() {},
    methods: {
        initCustom() {
            this.pageParam.pageNo = 1
            this.getCustomList()
        },
        getCustomList() {
            this.loading = true
            this.$api.post(`chm/baseDrugsComponent/queryComponentDrugsPage`, {
                conditionList: this.conditionList,
                page: this.pageParam,
                model: this.invRow.comName,
                // sort: this.sortParam ? this.sortParam : this.initSort
            })
                .then(res => {
                    if (res.success) {
                        this.pageParam.total = res.data.totalCount
                        this.list = res.data.records
                    }
                })
                .finally(() => {
                    this.loading = false
                })
        },
        handleSizeChange(val) {
            this.pageParam.pageSize = val
            this.handleCurrentChange(1)
        },
        handleCurrentChange(page) {
            this.pageParam.pageNo = page
            this.getCustomList()
        },
        initSendValue(data) {
            this.invRow = data
            this.getDrugsNumber()
            this.initCustom()
        },
        //该成分的药品数量
        getDrugsNumber() {
            this.$api.get('chm/baseDrugsComponent/queryComponentDrugsNumber', {comName: this.invRow.comName}).then(res=>{
                let drugsNumber = 0
                if(res.data) {
                    drugsNumber = res.data
                }
                this.drugsNumber =drugsNumber
            })
        },

        getComList(query = '') {
            let params = {
                comName: this.invRow.comName,
                keyword: query
            }
            this.comObj.loading = true
            this.$api.get('chm/baseDrugsComponent/queryComponentDrugsList', params).then(res=>{
                let options = []
                if(res.data && res.data.length !== 0) {
                    res.data.forEach(ele=>{
                        options.push({
                            value: ele.comCode,
                            label: ele.comName
                        })
                    })
                }
                this.comObj.options = options
            }).finally(()=>{
                this.comObj.loading = false
            })
        },

        //药品搜索
        remoteMethodCom(query) {
            this.getComList(query)
        },

        onSearch() {
            let conditionList = []
            if(this.comObj.value) {
                conditionList.push({
                    name: "comCode",
                    op: "in",
                    value: [this.comObj.value]
                })
            }
            this.conditionList = conditionList
            this.initCustom()
        }
    }
}
</script>