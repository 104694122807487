<style lang="scss">
@import "~@/assets/css/var";
.my-table-one {
    &__title{
        font-weight: bold;
        margin: 20px;    
        font-size: 14px;
        display: flex;
        justify-content: space-between;
    }
    .ts-table {
        background: #fff;
        padding-right: 10px;
        margin-top: 0 !important;
    }
    .my-table {
        padding: 20px 10px;
    }
}
</style>

<template>
    <div class="common-table my-table-one">
        <div class="my-table-one__title">
            <div style="display: flex; align-items: center;">
                <div>历年药品各企业销售额</div>
                <el-select clearable style="width: 150px; padding: 0 10px;" v-model="comObj.value" 
                    filterable remote reserve-keyword placeholder="请输入药品名称"
                    :remote-method="remoteMethodCom" :loading="comObj.loading">
                    <el-option v-for="item in comObj.options" :key="item.value"
                        :label="item.label" :value="item.value">
                    </el-option>
                </el-select>

                <el-select clearable style="width: 150px; padding: 0 10px;" v-model="compObj.value" 
                    filterable remote reserve-keyword placeholder="请输入企业名称"
                    :remote-method="remoteMethodComp" :loading="compObj.loading">
                    <el-option v-for="item in compObj.options" :key="item.value"
                        :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
                <!-- <el-input style="width: 150px" v-model="compName" placeholder="请输入企业名称"></el-input> -->
                <el-button style="margin-left: 10px;" type="primary" @click="onSearch">查询</el-button>
            </div>
            <ts-table-operate  v-if="cols.length" :cols="cols" show-export hideSearch :styleText="'position: unset;'"
            @leadingOut="leadingOutTwo" ></ts-table-operate>
        </div>
        <div class="ts-table">
            <ts-table class="make-table" style="flex: 3" :cols="cols" hide-border :data="list" 
                :page-param="pageParam" :loading="loading" hide-detail :height="null" :cmds="cmds"
                @handleSizeChange="handleSizeChange" @handleSort="sort" :summaryMethod="summaryFun" 
                @handleCurrentChange="handleCurrentChange" >
            </ts-table>
        </div>
    </div>
</template>

<script>
// import variable from "@/util/variable";
import myMixin from '@/util/mixin'
export default {
    mixins: [myMixin],
    data() {
        return {
            stop: true,
            loading: false,
            pageParam: {
                pageNo: 1,
                pageSize: 10,
                total: 0,
            },
            defalutSize: 10,
            currentType: '',
            conditionList: [],
            list: [],
            cols: this.$help.generalCols([
                {
                    fieldName: 'comName',
                    name: '药品名称',
                    width: 100,
                    listQueryView: '是',
                    listQueryModel: 1,
                    // listStipulate: 1
                },
                {
                    fieldName: 'compName',
                    name: '企业名称',
                    width: 200,
                    listQueryView: '是',
                    listQueryModel: 1,
                    // listStipulate: 1
                },
                ...this.$variable.getYearCols()
            ]),
            cmds: [],
            sumData: [],
            invRow: {},
            drugsNumber: 0,

            comObj: {
                value: '',
                loading: false,
                options: []
            },
            compObj: {
                value: '',
                loading: false,
                options: []
            },
            initSort:{ 
                amount10: "desc"
            },
            currentParam:{
                export:'chm/saleYear/exportSumAmountAndRateGroupByCompNameAndComNameByComName'
            },
        }
    },
    created() {},
    methods: {
        leadingOutTwo(){
            this.currentParam.export = `${this.currentParam.export}?comName=${this.invRow.comName}`
            this.leadingOut()
        },
        initCustom() {
            this.pageParam.pageNo = 1
            this.getCustomList()
        },
        getCustomList() {
            // console.log(this.sortParam)
            this.loading = true
            this.$api.post(`chm/saleYear/sumAmountAndRateGroupByCompNameAndComNameByComName?comName=${this.invRow.comName}`, {
                conditionList: this.conditionList,
                page: this.pageParam,
                sort: JSON.stringify(this.sortParam) !== '{}' ? this.sortParam : this.initSort
            })
                .then(res => {
                    if (res.success) {
                        this.pageParam.total = res.data.totalCount
                        this.list = res.data.records
                        this.sumData = res.data.sumData
                    }
                })
                .finally(() => {
                    this.loading = false
                })
        },
        sort(param) {
            this.sortParam = {}
            if (param) {
                this.sortParam = param
            }
            this.initCustom()
        },
        handleSizeChange(val) {
            this.pageParam.pageSize = val
            this.handleCurrentChange(1)
        },
        handleCurrentChange(page) {
            this.pageParam.pageNo = page
            this.getCustomList()
        },
        initSendValue(data) {
            this.invRow = data
            this.initCustom()
        },
        getComList(query = '') {
            let params = {
                comName: this.invRow.comName,
                keyword: query
            }
            this.comObj.loading = true
            this.$api.get('chm/baseDrugsComponent/queryComponentDrugsList', params).then(res=>{
                let options = []
                if(res.data && res.data.length !== 0) {
                    res.data.forEach(ele=>{
                        options.push({
                            value: ele.comCode,
                            label: ele.comName
                        })
                    })
                }
                this.comObj.options = options
            }).finally(()=>{
                this.comObj.loading = false
            })
        },
        getCompList(query = '') {
            let params = {
                comName: this.invRow.comName,
                keyword: query
            }
            this.compObj.loading = true
            this.$api.get('chm/baseDrugsComponent/queryComponentCompPage', params).then(res=>{
                let options = []
                if(res.data && res.data.length !== 0) {
                    res.data.forEach(ele=>{
                        options.push({
                            value: ele.compCode,
                            label: ele.compName
                        })
                    })
                }
                this.compObj.options = options
            }).finally(()=>{
                this.compObj.loading = false
            })
        },
        //药品搜索
        remoteMethodCom(query) {
            this.getComList(query)
        },
        //企业搜索
        remoteMethodComp(query) {
            this.getCompList(query)
        },

        onSearch() {
            let conditionList = []
            if(this.comObj.value) {
                conditionList.push({
                    name: "comCode",
                    op: "in",
                    value: [this.comObj.value]
                })
            }
            if(this.compObj.value) {
                conditionList.push({
                    name: "compCode",
                    op: "in",
                    value: [this.compObj.value]
                })
            }
            // console.log(this.comObj.value, conditionList)
            this.conditionList = conditionList
            this.outSearchReal = conditionList
            this.initCustom()
        }
    }
}
</script>