<style lang="scss">
@import "~@/assets/css/var";
.my-table-one {
    &__title{
        font-weight: bold;
        margin: 20px;    
        font-size: 14px;
    }
    .ts-table {
        background: #fff;
        padding-right: 10px;
        margin-top: 0 !important;
    }
    .my-table {
        padding: 20px 10px;
    }
}
</style>

<template>
    <div class="common-table my-table-one">
        <div class="my-table-one__title">
            <el-select class="show-cart" 
                v-model="comObj.value" filterable reserve-keyword remote
                :remote-method="remoteMethodCom" @change="comChange" :loading="comObj.loading">
                <el-option v-for="item in comObj.options" :key="item.value" :label="item.label"
                    :value="item.value"></el-option>
            </el-select>
        </div>
        <div class="ts-table">
            <ts-table class="make-table" style="flex: 3" :cols="cols" hide-border :data="list" 
                :page-param="pageParam" :loading="loading" hide-detail height="60vh" :cmds="cmds"
                @handleSizeChange="handleSizeChange"
                @handleCurrentChange="handleCurrentChange" :hidePage="true" @seePriceChartOne="seePriceChartOne">
                <template v-slot:slot_priceDayPercent="{props}">
                    <div v-if="props.row.priceDayPercent == 0">
                        {{ props.row.priceDayPercent | changeNum}}
                    </div>
                    <div v-else :class="props.row.priceDayPercent < 0 ? 'color-green' : 'color-red'">
                        {{ props.row.priceDayPercent | changeNum}}
                    </div>
                </template>
                <template v-slot:slot_priceWeekPercent="{props}">
                    <div v-if="props.row.priceWeekPercent == 0">
                        {{ props.row.priceWeekPercent | changeNum}}
                    </div>
                    <div v-else :class="props.row.priceWeekPercent < 0 ? 'color-green' : 'color-red'">
                        {{ props.row.priceWeekPercent | changeNum}}
                    </div>
                </template>
                <template v-slot:slot_priceMonthPercent="{props}">
                    <div v-if="props.row.priceMonthPercent == 0">
                        {{ props.row.priceMonthPercent | changeNum}}
                    </div>
                    <div v-else :class="props.row.priceMonthPercent < 0 ? 'color-green' : 'color-red'">
                        {{ props.row.priceMonthPercent | changeNum}}
                    </div>
                </template>
                <template v-slot:slot_priceQuarterPercent="{props}">
                    <div v-if="props.row.priceQuarterPercent == 0">
                        {{ props.row.priceQuarterPercent | changeNum}}
                    </div>
                    <div v-else :class="props.row.priceQuarterPercent < 0 ? 'color-green' : 'color-red'">
                        {{ props.row.priceQuarterPercent | changeNum}}
                    </div>
                </template>
                <template v-slot:slot_priceYearPercent="{props}">
                    <div v-if="props.row.priceYearPercent == 0">
                        {{ props.row.priceYearPercent | changeNum}}
                    </div>
                    <div v-else :class="props.row.priceYearPercent < 0 ? 'color-green' : 'color-red'">
                        {{ props.row.priceYearPercent | changeNum}}
                    </div>
                </template>
            </ts-table>
        </div>
    </div>
</template>

<script>
import myMixin from '@/util/mixin'
// import variable from "@/util/variable";
export default {
    mixins: [myMixin],
    data() {
        return {
            loading: false,
            stop: true,
            pageParam: {
                pageNo: 1,
                pageSize: 20,
                total: 0,
            },
            defalutSize: 10,
            currentType: '',
            conditionList: [],
            list: [],
            cols: this.$help.generalCols([
                {
                    fieldName: 'comName',
                    name: '药品通用名',
                    width: 200,
                    listQueryView: '是',
                    listQueryModel: 1,
                    medicinal:true,
                    listOrderView: '是',
                },
                {
                    fieldName: 'materialSpec',
                    name: '规格',
                    width: 180,
                    listQueryView: '是',
                    listQueryModel: 1,
                    listOrderView: '是',
                },
                {
                    fieldName: 'price',
                    name: '近期价格',
                    width: 100,
                    listQueryView: '是',
                    listQueryModel: 1,
                    listOrderView: '是',
                    listClass: 'tc'
                },
                {
                    fieldName: 'priceDayPercent',
                    name: '较昨日涨跌',
                    width: 150,
                    listQueryView: '是',
                    listQueryModel: 1,
                    listOrderView: '是',
                    listClass: 'tc'
                    // listOrderView: '是',
                },
                {
                    fieldName: 'priceWeekPercent',
                    name: '周涨跌',
                    width: 150,
                    listQueryView: '是',
                    listOrderView: '是',
                    listQueryModel: 1,
                    listClass: 'tc'
                    // listOrderView: '是',
                },
                {
                    fieldName: 'priceMonthPercent',
                    name: '月涨跌',
                    width: 150,
                    listQueryView: '是',
                    listQueryModel: 1,
                    listOrderView: '是',
                    listClass: 'tc'
                    // listOrderView: '是',
                },
                {
                    fieldName: 'priceQuarterPercent',
                    name: '季涨跌',
                    width: 150,
                    listQueryView: '是',
                    listQueryModel: 1,
                    listOrderView: '是',
                    listClass: 'tc'
                    // listOrderView: '是',
                },
                {
                    fieldName: 'priceYearPercent',
                    name: '年涨跌',
                    listOrderView: '是',
                    width: 150,
                    listQueryView: '是',
                    listQueryModel: 1,
                    listClass: 'tc'
                    // listOrderView: '是',
                },
            
            ]),
            cmds: [],
            rank1: [],
            rank2: [],

            invRow: {},
            drugsNumber: 0,

            comObj: {
                value: '',
                loading: false,
                options: []
            }
        }
    },
    created() {
        this.getComList('红花逍遥胶囊', true)
    },
    filters: {
        changeNum(val) {
            let str = val
            if(val == '0') {
                str = '平'
            }else if(val === '--') {
                str = '--'
            }else {
                str = (val * 100).toFixed(2) + '%'
            }
            return str
        }
    },
    methods: {
        seePriceChartOne(row){
            this.$emit('seePriceChart',row);
        },
        initCustom() {
            // this.pageParam.pageNo = 1
            this.getCustomList()
        },
        getCustomList() {
            this.loading = true
            this.$api.get(`chm/aimChineseMedicinalPrice/queryAimChineseMedicinalPriceByMasterComponent`, 
                {comCode: this.comObj.value}).then(res => {
                if (res.success) {
                    this.list = res.data
                }
            })
            .finally(() => {
                this.loading = false
            })
        },
        handleSizeChange(val) {
            this.pageParam.pageSize = val
            this.handleCurrentChange(1)
        },
        handleCurrentChange(page) {
            this.pageParam.pageNo = page
            this.getCustomList()
        },
        initSendValue(data) {
            this.invRow = data
        },

        getComList(query = '', first = '') {
            let params = { keyword: query }
            this.comObj.loading = true
            this.$api.get('setting/aimBaseDrugs/queryChineseAimBaseDrugsListByKeyword', params).then(res=>{
                let options = []
                if(res.data && res.data.length !== 0) {
                    res.data.forEach(ele=>{
                        options.push({
                            value: ele.comCode,
                            label: ele.comName
                        })
                    })
                }
                this.comObj.options = options
                if (first && options.length !== 0) {
                    this.comObj.value = options[0].value
                    this.initCustom();
                }
            }).finally(()=>{
                this.comObj.loading = false
            })
        },

        //药品搜索
        remoteMethodCom(query) {
            this.getComList(query)
        },

        comChange() {
            this.initCustom()
        },
    }
}
</script>