<style lang="scss">
@import "~@/assets/css/var";
.my-table-one {
    &__title{
        font-weight: bold;
        margin: 20px;    
        font-size: 14px;
    }
    .ts-table {
        background: #fff;
        padding-right: 10px;
        margin-top: 0 !important;
    }
    .my-table {
        padding: 20px 10px;
        .color-red {
            color: $red;
        }
        .color-green {
            color: $green;
        }
    }
}
</style>

<template>
    <div class="common-table my-table-one">
        <div class="my-table-one__title">
            <div style="display: flex; align-items: center;">
                <el-select class="show-cart" 
                    v-model="comObj.value" filterable reserve-keyword remote
                    :remote-method="remoteMethodCom" @change="comChange" :loading="comObj.loading">
                    <el-option v-for="item in comObj.options" :key="item.value" :label="item.label"
                        :value="item.value"></el-option>
                </el-select>
            </div>
        </div>
        <div class="ts-table">
            <ts-table class="make-table" style="flex: 3" :cols="cols" hide-border :data="list" 
                :page-param="pageParam" :loading="loading" hide-detail height="55vh" :cmds="cmds"
                @handleSizeChange="handleSizeChange" @handleSort="sort" 
                @handleCurrentChange="handleCurrentChange" @seePriceChartOne="seePriceChartOne">
                <template v-slot:slot_weeklyUps="{props}">
                    <div v-if="props.row.weeklyUps == 0">
                        {{ props.row.weeklyUps | changeNum}}
                    </div>
                    <div v-else :class="props.row.weeklyUps < 0 ? 'color-green' : 'color-red'">
                        {{ props.row.weeklyUps | changeNum}}
                    </div>
                </template>
                <template v-slot:slot_monthlyUps="{props}">
                    <div v-if="props.row.monthlyUps == 0">
                        {{ props.row.monthlyUps | changeNum}}
                    </div>
                    <div v-else :class="props.row.monthlyUps < 0 ? 'color-green' : 'color-red'">
                        {{ props.row.monthlyUps | changeNum}}
                    </div>
                </template>
                <template v-slot:slot_yearlyUps="{props}">
                    <div v-if="props.row.yearlyUps == 0">
                        {{ props.row.yearlyUps | changeNum}}
                    </div>
                    <div v-else :class="props.row.yearlyUps < 0 ? 'color-green' : 'color-red'">
                        {{ props.row.yearlyUps | changeNum}}
                    </div>
                </template>
                <template v-slot:slot_priceChart="{props}">
                    <div class="comment-star">
                        <div class="comment-star__item" v-if="props.row.comCode && props.row.comName">
                            <svg @click="seePriceChart(props.row)" style="width: 20px;height: 20px" t="1673858334212" class="icon" viewBox="0 0 1024 1024" version="1.1"
                                xmlns="http://www.w3.org/2000/svg" p-id="1514" width="128" height="128">
                                <path d="M124.928 660.48h104.96v238.08h-104.96zM347.136 535.552h104.96v363.52h-104.96zM548.864 644.608h104.96v254.464h-104.96zM766.976 422.4h104.96v476.672h-104.96z"
                                    :fill="props.row.clicked ? '#F11C1C' : '#5171DA'" p-id="1515"></path>
                                <path d="M666.112 124.928l110.592 118.784L535.04 473.6 351.744 307.2v-0.512l-0.512-0.512-1.536 2.048L61.44 536.576l32.768 41.472 254.976-202.752 186.368 169.472 1.024-1.024 0.512 0.512 275.456-262.144 100.864 108.544 12.8-260.096z"
                                    :fill="props.row.clicked ? '#F11C1C' : '#5171DA'" p-id="1516"></path>
                            </svg>
                        </div>
                    </div>
                </template>
            </ts-table>
        </div>
    </div>
</template>

<script>
import myMixin from '@/util/mixin'
export default {
    mixins: [myMixin],
    data() {
        return {
            stop: true,
            loading: false,
            pageParam: {
                pageNo: 1,
                pageSize: 20,
                total: 0,
            },
            defalutSize: 10,
            currentType: '',
            conditionList: [],
            list: [],
            cols: this.$help.generalCols([
                {
                    fieldName: 'comName',
                    name: '药品名称',
                    width: 180,
                    listQueryView: '是',
                    listOrderView: '是',
                    listQueryModel: 1,
                    medicinal:true
                },
                {
                    fieldName: 'materialSpec',
                    name: '规格',
                    listOrderView: '是',
                    width: 200,
                    listQueryView: '是',
                    listQueryModel: 1,
                },
                {
                    fieldName: 'marketPlaceCodeName',
                    name: '市场',
                    listOrderView: '是',
                    width: 200,
                    listQueryView: '是',
                    listQueryModel: 1,
                    // listOrderView: '是',
                },
                {
                    fieldName: 'productionPlace',
                    name: '产地',
                    width: 200,
                    listOrderView: '是',
                    listQueryView: '是',
                    listQueryModel: 1,
                    // listOrderView: '是',
                },
                {
                    fieldName: 'price',
                    name: '近期价格',
                    listOrderView: '是',
                    width: 100,
                    listQueryView: '是',
                    listQueryModel: 1,
                    // listOrderView: '是',
                },
                {
                    fieldName: 'weeklyUps',
                    name: '周涨跌',
                    width: 80,
                    listOrderView: '是',
                    listQueryView: '是',
                    listQueryModel: 1,
                    // listOrderView: '是',
                },
                {
                    fieldName: 'monthlyUps',
                    name: '月涨跌',
                    width: 80,
                    listOrderView: '是',
                    listQueryView: '是',
                    listQueryModel: 1,
                    // listOrderView: '是',
                },
                {
                    fieldName: 'yearlyUps',
                    name: '年涨跌',
                    width: 80,
                    listOrderView: '是',
                    listQueryView: '是',
                    listQueryModel: 1,
                    // listOrderView: '是',
                },
                {
                    fieldName: 'priceChart',
                    name: '价格图',
                    width: 100,
                    listQueryView: '是',
                    listQueryModel: 1,
                }
            ]),
            cmds: [],

            rank1: [],
            rank2: [],

            invRow: {},
            drugsNumber: 0,

            comObj: {
                value: '',
                loading: false,
                options: []
            }
        }
    },
    created() {
        this.getComList()
        this.initCustom()
    },
    filters: {
        changeNum(val) {
            return val == '0' ? '平' : val + '%';
        }
    },
    methods: {
        sort(param) {
            this.sortParam = {}
            if (param) {
                this.sortParam = param
            }
            this.initCustom();
        },
        seePriceChartOne(row){
            this.$emit('seePriceChart',row);
        },
        initCustom() {
            this.pageParam.pageNo = 1
            this.getCustomList()
        },
        getCustomList() {
            this.loading = true
            this.$api.post(`chm/aimChineseMedicinalPrice/queryAimChineseMedicinalPriceGroupByPage`, {
                conditionList: this.conditionList,
                page: this.pageParam,
                sort: this.sortParam ? this.sortParam : this.initSort
            })
                .then(res => {
                    if (res.success) {
                        this.pageParam.total = res.data.totalCount
                        this.list = res.data.records
                    }
                })
                .finally(() => {
                    this.loading = false
                })
        },
        handleSizeChange(val) {
            this.pageParam.pageSize = val
            this.handleCurrentChange(1)
        },
        handleCurrentChange(page) {
            this.pageParam.pageNo = page
            this.getCustomList()
        },

        getComList(query = '') {
            this.comObj.loading = true
            this.$api.get('chm/ChineseMaterial/queryChineseMaterialComNameListByKeyword', {keyword: query}).then(res=>{
                let options = []
                if(res.data && res.data.length !== 0) {
                    res.data.forEach(ele=>{
                        options.push({
                            value: ele.comCode,
                            label: ele.comName
                        })
                    })
                }
                this.comObj.options = options
            }).finally(()=>{
                this.comObj.loading = false
            })
        },

        //药品搜索
        remoteMethodCom(query) {
            this.getComList(query)
        },

        seePriceChart(row) {
            this.$emit('seePriceChart', row)
        },

        comChange() {
            let conditionList = []
            if(this.comObj.value) {
                conditionList.push({
                    name: "comCode",
                    op: "in",
                    value: [this.comObj.value]
                })
            }
            this.conditionList = conditionList
            this.initCustom()
        }
    }
}
</script>