<style lang="scss">
@import "~@/assets/css/var";
.my-table-one {
    &__title{
        font-weight: bold;
        margin: 20px;    
        font-size: 14px;
    }
    .ts-table {
        background: #fff;
        padding-right: 10px;
        margin-top: 0 !important;
    }
    .inv-echart{
        #ch-inv-echart{
            width: 1177px;
        }
    }
    .my-table {
        padding: 20px 10px;
    }
}
</style>

<template>
    <div class="common-table my-table-one">
        <div class="my-table-one__title">历年销售数据</div>
        <div class="ts-table inv-echart" v-loading="loading">
            <div ref="canvas" :id="canvasId" class="canvas"></div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            loading: false,
            canvasId: 'ch-inv-echart',
            invRow: {},
            list: []
        }
    },
    created() {
        // this.init()
    },
    methods: {
        getEchartData() {
            this.loading = true
            this.$api.get('chm/saleYear/sumYearAmountAndRateByComName', {comName: this.invRow.comName})
                .then(res => {
                    if (res.code === this.$variable.successCode) {
                        this.list = res.data.map(item => {
                            // item.year = this.$variable.yearMap[item.year]
                            item.rate = this.$help.parseFloat100(item.rate)
                            return item
                        })
                        this.$help.renderBar(this.canvasId, res.data, 'year')
                    }
                }).finally(()=>{
                    this.loading = false
                })
        },
        initSendValue(data) {
            this.invRow = data
            this.getEchartData()
        },
    }
}
</script>