<style lang="scss">
@import "~@/assets/css/var.scss";

.ch-medicine {
    width: 1400px;
    min-height: 400px;
    margin: 0 auto;
    .el-tabs{
        margin-top: 20px;
    }
    .select {
        padding-top: 30px;
        padding-bottom: 20px;
        background: #fff;
        left: 0;
        right: 0;
        z-index: 10;
        display: flex;

        .el-select {
            width: 300px;
        }

        .tag-group {
            margin-left: 20px;
            .el-tag {
                margin-right: 20px;
                cursor: pointer;
            }
        }
    }

    .el-form-item__label {
        font-weight: bold;
    }

    .container {
        background: #F3F5F8;
        padding-top: 20px;
        height: 62vh;
        overflow: auto;
        .title {
            font-weight: bold;
            font-size: 18px;
            margin-bottom: 10px;
        }

        .base-info {
            padding: 20px;
            margin: 0 20px 20px;
            background: #fff;

            .info {
                margin: 15px 0;
                display: flex;

                .label {
                    flex: 1;
                    font-weight: bold;
                    width: 100px;
                    text-align: right;
                }

                p {
                    flex: 10;
                }
            }
        }

        .market-producer {
            margin: 20px;
            padding: 20px;
            background: #fff;

            .is-active {
                background-color: $main;
                color: #fff;
            }

            .el-tab-pane {
                .el-tag {
                    margin-right: 10px;
                    margin-bottom: 10px;
                    cursor: pointer;
                }
            }

            .trend-chart {
                display: flex;

                .trend-canvas {
                    flex: 4;

                    .canvas-head {
                        display: flex;
                        align-items: center;

                        .el-radio-group {
                            margin-left: 10px;
                        }
                    }

                    .canvas {
                        margin: 20px 0;
                        width: 1022px;
                        height: 400px;
                    }
                }

                .trend-price {
                    flex: 1;

                    .day {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        &-price {
                            line-height: 30px;
                            font-size: 14px;
                            font-weight: bold;

                            .num {
                                color: $red;
                                padding-left: 10px;
                            }
                        }

                        &-date {
                            .num {
                                padding-left: 5px;
                            }
                        }
                    }

                    .table {
                        border-top: 1px solid #ddd;
                        border-bottom: 1px solid #ddd;
                    }

                    .table tr td {
                        width: 100px;
                        line-height: 30px;
                        text-align: center;
                    }

                    .increase {
                        display: flex;
                        flex-wrap: wrap;

                        &-item {
                            width: 33.3%;
                            margin-top: 20px;
                            text-align: center;
                        }
                    }

                    .instructions{
                        padding: 10px;
                    }
                }
            }
        }

        .color-red {
            color: #ff3535;
        }

        .color-green {
            color: #4caf50;
        }
    }
}

</style>

<template>
    <div class="ch-medicine" v-if="!$help.token.get()">
        <ts-error ></ts-error>
    </div>
    <div class="ch-medicine" v-else v-loading="loading" element-loading-text="正在加载中药材数据">
        <el-tabs type="border-card" v-model="activeName">
            <el-tab-pane label="中药材综合" name="first">
                <div class="select">
                    <el-form label-width="120px">
                        <el-form-item label="选择中药材">
                            <el-select class="show-cart" v-model="select.model" :loading="select.loading" filterable reserve-keyword remote
                                :remote-method="loadChMedicine" @change="comChange">
                                <el-option v-for="item in select.list" :key="item.comCode" :label="item.comName"
                                    :value="item.comCode"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-form>
                    <div class="tag-group">
                        <el-tag @click="onClickTag(index, item)" v-for="(item, index) in tagList" :key="item.label"
                            :type="item.type" size="medium" :effect="index === tagIndex ? 'dark' : 'plain'">
                            {{ item.label }}
                        </el-tag>
                    </div>
                </div>
                <div class="container" v-if="JSON.stringify(baseInfo) !== '{}'">
                    <div class="base-info" id="section1">
                        <div class="title">{{ invRow.comName }}基本信息</div>
                        <div class="info" v-for="item in baseCols" :key="item.field">
                            <div class="label">{{ item.label }}</div>
                            <p>{{ baseInfo[item.field] }}</p>
                        </div>
                    </div>
                    <div class="market-producer" id="section2">
                        <div class="title">{{ invRow.comName }}价格趋势</div>
                        <el-tabs type="card" v-model="activeSepc" style="margin-top: 20px">
                            <el-tab-pane label="规格" name="1">
                                <div v-if="market.specList.length > 0">
                                    <el-tag size="medium" v-for="(spec, index) in market.specList" :key="index"
                                        :effect="market.specIndexList.includes(index) ? 'dark' : 'plain'"
                                        @click="clickMarketSpecTag(spec, index)" type="success">
                                        {{ spec.name }}
                                    </el-tag>
                                    <div class="trend-chart" v-loading="echartLoading">
                                        <div class="trend-canvas">
                                            <div class="canvas-head">
                                                <el-date-picker v-if="trendForm.priceRadio === '1'" v-model="trendForm.dateList" 
                                                    type="daterange" range-separator="至" @change="changeDate"
                                                    start-placeholder="开始日期" end-placeholder="结束日期" style="width: 300px;" >
                                                </el-date-picker>
                                                <el-date-picker v-else v-model="trendForm.dataMonthList" 
                                                    type="monthrange" range-separator="至" @change="changeDate"
                                                    start-placeholder="开始月份" end-placeholder="结束月份" style="width: 300px;" >
                                                </el-date-picker>
                                                <el-radio-group v-model="trendForm.typeRadio" @input="changeType">
                                                    <el-radio-button label="MARKET">市场</el-radio-button>
                                                    <el-radio-button label="PRODUCTION">产地</el-radio-button>
                                                </el-radio-group>
                                                <el-radio-group v-model="trendForm.priceRadio" @input="changeDateType">
                                                    <el-radio-button label="1">日价格</el-radio-button>
                                                    <el-radio-button label="2">月价格</el-radio-button>
                                                </el-radio-group>
                                            </div>
                                            <div ref="canvas" :id="trendForm.canvasId" class="canvas"></div>
                                        </div>
                                        <div class="trend-price">
                                            <div class="day">
                                                <div class="day-price">
                                                    <span class="name">昨日价格:</span>
                                                    <span class="num">{{ dayPrice.preDayPrice }}</span>
                                                </div>
                                                <div class="day-date">
                                                    <span class="name">今日涨跌:</span>
                                                    <span v-if="dayPrice.preDayPrice && dayPrice.price">
                                                        <span class="num color-red"
                                                            v-if="dayPrice.preDayPrice <= dayPrice.price">
                                                            <i class="el-icon-top-right"></i>
                                                            {{ getRate(dayPrice.preDayPrice || 0, dayPrice.price || 0) }}
                                                        </span>
                                                        <span class="num color-green" v-else>
                                                            <i class="el-icon-bottom-right"></i>
                                                            {{ getRate(dayPrice.preDayPrice || 0, dayPrice.price || 0) }}
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="day">
                                                <div class="day-price">
                                                    <span class="name">今日价格:</span>
                                                    <span class="num">{{ dayPrice.price }}</span>
                                                </div>
                                                <div class="day-date">
                                                    <span class="name">日期:</span>
                                                    <span class="num">{{ trendForm.date }}</span>
                                                </div>
                                            </div>
                                            <table class="table" cellspacing="10">
                                                <tbody>
                                                    <tr>
                                                        <td></td>
                                                        <td>近一个月</td>
                                                        <td>近半年</td>
                                                        <td>近一年</td>
                                                    </tr>
                                                    <tr>
                                                        <td>最高：</td>
                                                        <td class="">
                                                            {{ trendForm.maxMinPrice.monthMaxPrice }}
                                                        </td>
                                                        <td class="">
                                                            {{ trendForm.maxMinPrice.halfYearMaxPrice }}
                                                        </td>
                                                        <td class="">{{ trendForm.maxMinPrice.yearMaxPrice }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>最低：</td>
                                                        <td class="">
                                                            {{ trendForm.maxMinPrice.monthMinPrice }}
                                                        </td>
                                                        <td class="">
                                                            {{ trendForm.maxMinPrice.halfYearMinPrice }}
                                                        </td>
                                                        <td class="">{{ trendForm.maxMinPrice.yearMinPrice }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div class="increase">
                                                <div class="increase-item" v-for="item in increaseList" :key="item.key">
                                                    <div class="name"> {{ item.value }}</div>
                                                    <div v-if="!isNaN(parseInt(dayPrice[item.key]))">
                                                        <span class="color-red" v-if="parseInt(dayPrice[item.key]) > 0">
                                                            {{ dayPrice[item.key] }}</span>
                                                        <span class="color-green" v-else>{{ dayPrice[item.key] }}</span>
                                                    </div>
                                                    <div v-else>
                                                        {{ dayPrice[item.key] }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="instructions">
                                                <strong class="color-red">说明:</strong>
                                                <p>1、单位：元/千克（除了罗汉果：元/个、蛤蚧：元/对、狗鞭：元/条、蜈蚣【大、中、小】：元/条）。</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-else>
                                    <el-empty description="暂无数据"></el-empty>
                                </div>
                            </el-tab-pane>
                        </el-tabs>
                    </div>
                    <div id="section3">
                        <ts-table-one ref="tableOne"></ts-table-one>
                    </div>
                    <div id="section4">
                        <ts-table-two ref="tableTwo"></ts-table-two>
                    </div>
                    <div id="section5">
                        <inv-echart ref="chInvEchart"></inv-echart>
                    </div>
                </div>
                <div class="no-data" v-else>
                    <el-empty description="请选择中药材"></el-empty>
                </div>
            </el-tab-pane> 
            <el-tab-pane label="中药材涨幅" name="second">
                <div id="section6">
                    <ts-table-three ref="tableThree" @seePriceChart="seePriceChart"></ts-table-three>
                </div>
            </el-tab-pane>
            <el-tab-pane label="药品成分涨幅" name="third">
                <div id="section7">
                    <ts-table-four ref="tableFour" @seePriceChart="seePriceChart"></ts-table-four>
                </div>
            </el-tab-pane>
            <!-- <el-tab-pane label="药品成本测算" name="four">
                <div id="section8">
                    <ts-table-five ref="tableFive"></ts-table-five>
                </div>
            </el-tab-pane> -->
        </el-tabs>
    </div>
</template>

<script>
import TsTableOne from './ChComponent/TsTableOne.vue';
import TsTableTwo from './ChComponent/TsTableTwo';
import TsTableThree from './ChComponent/TsTableThree';
import TsTableFour from './ChComponent/TsTableFour.vue';
import InvEchart from './ChComponent/InvEchart.vue';
// import TsTableFive from './ChComponent/TsTableFive.vue';
export default {
    components: { TsTableTwo, InvEchart, TsTableOne, TsTableThree, TsTableFour},
    data() {
        return {
            visitId: new Date().getTime(),
            pageId: 'ChMedicinalPrice',
            pageName: '中药材价格',

            select: {
                model: null,
                list: [],
                loading: false,
            },

            baseCols: [
                { label: "【所属部位】", field: "location" },
                { label: "【功效】", field: "effect" },
                // { label: "【药品编码】", field: "comCode" },
                { label: "【药品通用名】", field: "comName" },
                { label: "【药品别名】", field: "aliasName" },
                { label: "【英文名】", field: "comNameEn" },
                { label: "【药用部位】", field: "medicinalParts" },
                { label: "【植物形态】", field: "morphology" },
                { label: "【功效作用】", field: "efficacyEffect" },
                { label: "【产地分布】", field: "distribution" },
                { label: "【采收加工】", field: "harvesting" },
                { label: "【药品性状】", field: "drugCharacter" },
                { label: "【性味归经】", field: "tropismTaste" },
                { label: "【临床应用】", field: "clinic" },
                { label: "【药理研究】", field: "pharmacology" },
                { label: "【主要成分】", field: "component" },
                { label: "【使用禁忌】", field: "contraindications" },
                { label: "【配伍药方】", field: "prescription" },
                // {label: '备注', field: 'remark'},
                // {label: '数据来源', field: 'dataSource'},
            ],
            baseInfo: {},

            marketProducer: {
                activeName: "1",
            },

            //今日价格
            dayPrice: {},
            //市场
            market: {
                list: [],
                index: 0,
                select: {},
                specList: [],
                specIndexList: [0],
                specSelectList: [],
            },
            //产地
            product: {
                list: [],
                index: 0,
                select: {},
                specList: [],
                specIndexList: [0],
                specSelectList: [],
            },
            activeSepc: "1",
            loading: false,
            form: {},

            trendForm: {
                dateList: [],
                dataMonthList: [],
                date: new Date().FormatShort(),
                priceRadio: "1",
                typeRadio: "MARKET",
                dayPirce: "",
                maxMinPrice: {},
                canvasId: "spec-canvas",
                dateData: [],
            },

            dayDate: {},
            monthDate: {},

            echartLoading: false,

            invRow: {},

            tagList: [
                { type: '', label: '基本信息', id: 'section1' },
                { type: '', label: '价格趋势', id: 'section2' },
                { type: '', label: '入药产品', id: 'section3' },
                { type: '', label: '企业销售', id: 'section4' },
                { type: '', label: '产品销售趋势', id: 'section5' }
            ],
            increaseList: [
                { key: 'priceDayPercent', value: '较昨日涨幅' },
                { key: 'priceWeekPercent', value: '近一周涨幅' },
                { key: 'priceMonthPercent', value: '近一月涨幅' },
                { key: 'priceHelpYearPercent', value: '近半年涨幅' },
                { key: 'priceYearPercent', value: '近一年涨幅' },
            ],
            tagIndex: 0,
            activeId: '',
            scrollPosition: 0,

            activeName: 'first',

            isJumpPage: false,
            jumpRow: {}
        };
    },
    filters: {
        percent(val) {
            // let str = '--';
            // if(val && val !== '--') {
            //     str = (val * 100).toFixed(2) + '%'
            // }
            return val
        }
    },
    created() {
        if (this.$help.token.get()) {
            if(JSON.stringify(this.$route.query) !== '{}'){
                this.loadChMedicine(this.$route.query.comName, true);
            }else{
                this.loadChMedicine('人参', true);
            }
        }
        this.initDate();
        this.$help.socket.send(this)
    },
    methods: {
        onClickTag(index, item) {
            this.tagIndex = index
            this.activeId = item.id
            if (item.id === 'section1') {
                let height = 0
                let t = setInterval(() => {
                    height += 50
                    if (height >= this.scrollPosition) {
                        clearInterval(t)
                        height = this.scrollPosition
                    }
                    document.getElementById(item.id).scrollIntoView({
                        block: 'start',
                        behavior: 'smooth'
                    });
                }, 20)
            } else {
                document.getElementById(item.id).scrollIntoView({
                    block: 'center',
                    behavior: 'smooth'
                });
            }
        },

        handleScroll() {
            let scrollPosition = window.pageYOffset ||
                document.documentElement.scrollTop ||
                document.body.scrollTop || 0;
            this.scrollPosition = scrollPosition
        },

        getAll() {
            let dateList = [];
            let startTime = this.$help.deepClone(this.trendForm.dateList[0]);
            let endTime = this.$help.deepClone(this.trendForm.dateList[1]);
            while (endTime.getTime() - startTime.getTime() >= 0) {
                let year = startTime.getFullYear();
                let month =
                    startTime.getMonth() + 1 < 10
                        ? "0" + (startTime.getMonth() + 1)
                        : startTime.getMonth() + 1;
                let day =
                    startTime.getDate().toString().length == 1
                        ? "0" + startTime.getDate()
                        : startTime.getDate();
                dateList.push(year + "-" + month + "-" + day);
                startTime.setDate(startTime.getDate() + 1);
            }
            return dateList;
        },

        getMonthAll() {
            let startYear = this.trendForm.dataMonthList[0].getFullYear(),
            startMonth = this.trendForm.dataMonthList[0].getMonth(),
            endYear = this.trendForm.dataMonthList[1].getFullYear(),
            endMonth = this.trendForm.dataMonthList[1].getMonth();
            let list= [];
            // 如果年份相等
            if(startYear === endYear) {
                for(let i = startMonth; i <= endMonth; i++){
                    let str = new Date(startYear, i, 1).FormatShort()
                    list.push(str)
                }
            }else {
                let year = endYear - startYear - 1;
                if(year > 0) {
                    for(let i = 0; i <= year; i++) {
                        for(let j = 1; j <= 12; j++){
                            let str = new Date(startYear + i, j, 1).FormatShort()
                            list.push(str)
                        }
                    }
                }else {
                    for(let i = startMonth; i <= 12; i++){
                        let str = new Date(startYear, i, 1).FormatShort()
                        list.push(str)
                    }
                    for(let i = 1; i <= endMonth; i++){
                        let str = new Date(endYear, i, 1).FormatShort()
                        list.push(str)
                    }
                }
            }
            return list
        },

        initDate() {
            let year = new Date().getFullYear();
            let month = new Date().getMonth()
            let today = new Date().getDate()
            this.trendForm.dateList[0] = new Date(year, month - 1, today);
            this.trendForm.dateList[1] = new Date(year, month, today);
            this.trendForm.dataMonthList[0] = new Date(year - 1, month - 1, today);
            this.trendForm.dataMonthList[1] = new Date(year, month, today);
            if(this.trendForm.priceRadio === '1') { //日
                this.trendForm.dateData = this.getAll();
            }else { //月
                this.trendForm.dateData = this.getMonthAll();
            }
        },

        // 加载中药材列表
        loadChMedicine(keyword, first = '') {
            this.select.loading = true;
            this.$api
                .get("chm/ChineseMaterial/queryChineseMaterialComNameListByKeyword", {
                    keyword,
                })
                .then((res) => {
                    if (res.data && res.data.length !== 0) {
                        this.select.list = res.data;
                        if (first) {
                            this.select.model = res.data[0].comCode
                            this.invRow = res.data[0]
                            this.getBaseInfo();
                        }
                    } else {
                        this.select.list = []
                        this.select.model = ''
                    }
                })
                .finally(() => {
                    this.select.loading = false;
                });
        },

        // 选择一个中药材
        comChange() {
            this.loading = true;
            this.isJumpPage = false
            this.jumpRow = {}
            this.getBaseInfo();
            let invRow = {}
            this.select.list.forEach(item => {
                if (item.comCode === this.select.model) {
                    invRow = item
                }
            })
            this.invRow = invRow
        },

        //中药材基本信息
        getBaseInfo() {
            this.$api
                .get("chm/ChineseMaterial/queryChineseMaterialDetail", {
                    comCode: this.select.model,
                })
                .then((res) => {
                    if(res.data){
                        this.baseInfo = res.data;
                        this.getComSpec();
                        this.$nextTick(() => {
                            this.$refs.tableOne && this.$refs.tableOne.initSendValue(this.invRow)
                            this.$refs.tableTwo && this.$refs.tableTwo.initSendValue(this.invRow)
                            // this.$refs.tableThree && this.$refs.tableThree.initSendValue(this.invRow)
                            // this.$refs.tableFour && this.$refs.tableFour.initSendValue(this.invRow)
                            this.$refs.chInvEchart && this.$refs.chInvEchart.initSendValue(this.invRow)
                            if(JSON.stringify(this.$route.query) !== '{}' && this.invRow.comCode === this.$route.query.comCode){
                                this.onClickTag(1, this.tagList[1])
                            }
                        })
                    }else{
                        this.baseInfo = {}
                        this.$store.commit('error', '暂无此药品数据！')
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        //获取市场字典表
        getMarketDict() {
            this.$api
                .post("sys/sysDictItem/querySysDictItemList", {
                    params: { headId: "MARKET_PLACE" },
                })
                .then((res) => {
                    if (res.data) {
                        this.market.list = res.data;
                        this.market.select = res.data[0];
                    } else {
                        this.market.list = [];
                        this.market.select = [];
                    }
                });
        },

        //中药材规格
        getComSpec() {
            let params = {
                comCode: this.select.model,
                // marketPlaceCode: this.market.select.dataCode
            };
            if(!params.comCode) return
            this.$api
                .get("chm/aimChineseMedicinalPrice/queryMaterialSpec", params)
                .then((res) => {
                    if (res.data && res.data.length !== 0) {
                        this.market.specList = res.data;
                        this.market.specSelectList = [res.data[0]];
                        this.initPriceAndEchart();
                    } else {
                        this.market.specList = [];
                        this.market.specSelectList = [];
                    }
                })
                .finally(() => {
                    this.loading = false;
                    if(this.isJumpPage) {
                        // debugger
                        this.slidePage()
                    }
                });
        },

        //市场规格
        clickMarketSpecTag(item, index) {
            //改成单选
            let indexList = this.market.specIndexList,
                selectList = this.market.specSelectList;
            indexList = [index];
            selectList = [item];
            this.market.specIndexList = indexList;
            this.market.specSelectList = selectList;
            this.initPriceAndEchart();
        },

        getMonthAndDay() {
            let params = {
                materialSpec: this.market.specSelectList[0].value,
                comCode: this.select.model,
            };
            //价格最高最低
            this.$api
                .post("chm/aimChineseMedicinalPrice/queryMaxMinPrice", { params })
                .then((res) => {
                    let maxMinPrice = {};
                    if (res.data) maxMinPrice = res.data;
                    this.trendForm.maxMinPrice = maxMinPrice;
                });
            //当天的价格
            this.$api.post("chm/aimChineseMedicinalPrice/queryDayPrice", { params })
            .then(res => {
                // console.log(res)
                if(res.data) {
                    this.dayPrice = res.data
                }
            });
        },

        getMarketEchart() {
            this.echartLoading = true
            let params = {
                materialSpec: this.market.specSelectList[0].value,
                comCode: this.select.model,
            };
            let startPriceMonthDate = this.trendForm.dataMonthList[0].FormatShort();
            let endPriceMonthDate = this.trendForm.dataMonthList[1].FormatShort();
            //月价
            this.$api.post("chm/aimChineseMedicinalPrice/queryAimChineseMedicinalMonthPricePage", {
                model: { 
                    startPriceDate: startPriceMonthDate, 
                    endPriceDate: endPriceMonthDate, 
                    contrastType: this.trendForm.typeRadio 
                },
                page: { pageNo: 1, pageSize: 10 },
                params,
            }).then((res) => {
                let monthDate = {};
                if (res.data) {
                    monthDate = res.data;
                }
                this.monthDate = monthDate;
                this.changeDateType()
            });
            let startPriceDate = this.trendForm.dateList[0].FormatShort();
            let endPriceDate = this.trendForm.dateList[1].FormatShort();
            //日价
            this.$api.post("chm/aimChineseMedicinalPrice/queryAimChineseMedicinalPricePage", {
                model: { startPriceDate, endPriceDate, contrastType: this.trendForm.typeRadio },
                page: { pageNo: 1, pageSize: 10 },
                params,
            }).then((res) => {
                let dayDate = {};
                if (res.data) {
                    dayDate = res.data;
                }
                this.dayDate = dayDate;
                this.changeDateType()
            }).finally(() => {
                this.echartLoading = false
            });

        },

        changeType() {
            this.getMarketEchart();
        },

        changeDateType() {
            if (this.trendForm.priceRadio === '1') { //日
                this.trendForm.dateData = this.getAll();
                if (this.$refs.canvas) {
                    this.$nextTick(() => {
                        this.renderMulLine(this.trendForm.canvasId, this.dayDate);
                    });
                }
            } else { //月
                this.trendForm.dateData = this.getMonthAll();
                if (this.$refs.canvas) {
                    this.$nextTick(() => {
                        this.renderMulLine(this.trendForm.canvasId, this.monthDate);
                    });
                }
            }
        },

        //对比增长率
        getRate(field1, field2) {
            let mol = field1 - 0; // (昨天)
            let den = field2 - 0; // (今天)
            if (mol === 0 && den === 0) {
                return '+' + 0 + '%'
            }
            if (mol === 0 && den !== 0) {
                return '+' + 100 + '%'
            }
            if (mol !== 0 && den === 0) {
                return '-' + 100 + '%'
            }
            if (den >= mol) {
                //增加
                return '+' + ((den - mol) / mol * 100).toFixed(2) + '%'
            } else {
                return '-' + ((mol - den) / mol * 100).toFixed(2) + '%'
            }
        },

        initPriceAndEchart() {
            this.getMonthAndDay();
            this.getMarketEchart();
        },

        changeDate(date) {
            if (date) {
                if(this.trendForm.priceRadio === '1') { //日
                    this.trendForm.dateList = date;
                    this.trendForm.dateData = this.getAll();
                }else { // 月
                    this.trendForm.dataMonthList = date;
                    this.trendForm.dateData = this.getMonthAll();
                }
                this.getMarketEchart();
            }
        },
        //处理echart的数据
        dealEchatData(data) {
            let legendData = [], seriesData = [], legendSelectedMap = {},
                dateData = this.trendForm.dateData, dataSortKey = ['综合趋势'], dataMap = [];
            dateData.forEach(item => {
                let obj = { priceDate: item, price: '' }
                dataMap.push(obj)
            })

            for (let key in data) {
                if (key !== '综合趋势') {
                    dataSortKey.push(key)
                }
            }

            dataSortKey.forEach(key => {
                let lineData = []
                legendData.push(key)
                if (key !== '综合趋势') {
                    legendSelectedMap[key] = false
                } else {
                    legendSelectedMap[key] = true
                }
                dataMap.map(parent => {
                    data[key] && data[key].forEach(child => {
                        if (parent.priceDate === child.priceDate) {
                            parent.price = child.price
                        }
                    })
                })
                dataMap.forEach(item => {
                    lineData.push(item.price)
                })
                seriesData.push({
                    name: key,
                    type: "line",
                    data: lineData,
                    connectNulls: true,
                })
            })
            return {
                legendData,
                legendSelectedMap,
                seriesData
            }
        },

        renderMulLine(el, data) {
            let collectData = this.dealEchatData(data)
            let xAxisData = []
            if(this.trendForm.priceRadio === '1') { //日
                xAxisData = this.trendForm.dateData;
            }else { // 月
                this.trendForm.dateData.forEach(item=>{
                    let str = item.split('-')[0] + '-' + item.split('-')[1];
                    xAxisData.push(str)
                })
            }
            let legendData = collectData.legendData
            let legendSelectedMap = collectData.legendSelectedMap
            let seriesData = collectData.seriesData
            let dataZoomEnd = 0, lengthRate = this.trendForm.dateData.length / 30;
            if (lengthRate <= 1) {
                dataZoomEnd = 100
            } else {
                dataZoomEnd = Math.ceil(100 / lengthRate)
            }
            const myChart = window.echarts.init(document.getElementById(el));
            myChart.clear();
            myChart.setOption({
                title: {
                    text: "",
                },
                tooltip: {
                    trigger: "axis",
                },
                legend: {
                    data: legendData,
                    selected: legendSelectedMap,
                },
                grid: {
                    left: "3%",
                    right: "4%",
                    bottom: "10%",
                    containLabel: true,
                },
                toolbox: {
                    show: false,
                },
                xAxis: {
                    type: "category",
                    boundaryGap: false,
                    data: xAxisData,
                },
                yAxis: {
                    type: "value",
                },
                dataZoom: [
                    {
                        type: "slider", // slider 在外部显示  inside 在内部可以横向拖动
                        height: 20,
                        show: true,
                        bottom: 10,
                        start: 0,
                        end: dataZoomEnd,  // 0 - 100 这里是百分比
                    },
                ],
                series: seriesData,
            });
            return myChart;
        },

        //价格图
        seePriceChart(row) {
            this.activeName = 'first';
            this.loading = true;
            this.isJumpPage = true
            if(row.comCode == "--" ){
                row.comCode = ''
                if(this.select.list!=[]){
                    this.select.list.forEach(item=>{
                        if(item.comName == row.comName){
                            row.comCode = item.comCode
                        }
                    })
                }
            }
            this.jumpRow = row
            let invRow = { comCode: row.comCode, comName: row.comName };
            let comList = this.select.list, bool = false;
            comList.forEach(item=>{
                if(item.comCode === row.comCode) bool = true
            })
            if (!bool) comList.unshift(invRow) 
            this.select.list = comList
            this.select.model = row.comCode == '--'?'':row.comCode
            this.invRow = invRow
            this.getBaseInfo();
        },

        slidePage() {
            setTimeout(() => {
                this.$nextTick(()=>{
                    let index = 1, item = this.tagList[index];
                    this.onClickTag(index, item)
                    if (this.market.specList.length !== 0) {
                        this.market.specList.forEach((item, index) => {
                            if (this.jumpRow.materialSpec === item.value) {
                                this.clickMarketSpecTag(item, index)
                            }
                        })
                    }
                })
            }, 500);
        }
    },
    mounted() {
        window.addEventListener("scroll", this.handleScroll);
    },
    beforeDestroy() {
        window.removeEventListener("scroll", this.handleScroll);
    },
};
</script>